import { ucFirst } from '../../utils/caseconverters';
import { getType } from '../../utils/helpers';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

import Icon from '../Icon';
import Image from '../Image';
import styles from './CardNews.module.scss';

const CardNews = ({
    title = '',
    href = '',
    publicationDate = '',
    heroImage = {},
    thumbnail = {},
    type = '',
    modifiers = [],
    topics = [],
    tag = 'div',
}) => {
    const { t } = useTranslation();
    const isPodcast = type === 'Podcast';
    const hasThumbnail = !!thumbnail && !!thumbnail.url;
    const hasImage =
        (!!thumbnail && !!thumbnail.url) || (!!heroImage && !!heroImage.url);

    const handleClick = () => {
        if (typeof window.dataLayer === 'undefined') return;
        window.dataLayer.push({
            event: 'news_interaction',
            news_type: getType(type),
            news_name: title,
        });
    };

    const classes = classNames(
        styles['CardNews'],
        {
            [styles['CardNews--Podcast']]: isPodcast,
            [styles['CardNews--Text']]: !hasImage,
        },
        modifiers.map((modifier) => styles['CardNews--' + ucFirst(modifier)])
    );

    const OuterElement = tag === 'li' ? 'li' : 'div';

    return (
        <OuterElement className={classes} onClick={handleClick}>
            <a className={styles['CardNews__Link']} href={href}>
                <span className="sr-only">{title}</span>
            </a>
            <div className={styles['CardNews__Text']}>
                <span className={styles['CardNews__Type']}>{type}</span>
                <span className={styles['CardNews__Date']}>
                    {publicationDate}
                </span>
                <h2 className={styles['CardNews__Title']}>{title}</h2>
            </div>
            {hasImage && (
                <figure className={styles['CardNews__Image']}>
                    <Image
                        image={hasThumbnail ? thumbnail : heroImage}
                        sizes={'(min-width: 1800px) 640px'}
                    />
                </figure>
            )}
            <div className={styles['CardNews__Bottom']}>
                {!!topics.length && (
                    <div className={styles['CardNews__Topics']}>
                        <h3 className={styles['CardNews__TopicsTitle']}>
                            {t('CardNews.topic')}
                        </h3>
                        <p className={styles['CardNews__TopicsText']}>
                            {topics.join(', ')}
                        </p>
                    </div>
                )}
                <div className={styles['CardNews__IconContainer']}>
                    <Icon
                        icon={'arrowRight'}
                        dimensions={{ width: '20px', height: '20px' }}
                    />
                </div>
            </div>
        </OuterElement>
    );
};

CardNews.propTypes = {};

const PodcastButton = ({ time = '' }) => {
    const { t } = useTranslation();

    return (
        <span className={styles['CardNews__Button']}>
            <Icon icon={'play'} dimensions={{ width: '8px', height: '10px' }} />
            <span>{t('CardNews.play')}</span>
            <span className={styles['CardNews__Time']}>{time}</span>
        </span>
    );
};

export default CardNews;
