import React, { useEffect, useState, useRef } from 'react';
import { A11y, Autoplay, Navigation, Scrollbar } from 'swiper/modules';
import { Base64 } from 'js-base64';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper/core';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import classNames from 'classnames';
import debounce from 'debounce';

import Button from '../Button/Button';
import CardNews from '../CardNews/CardNews';
import styles from './NewsList.module.scss';

const NewsList = ({ title = '', link = {}, links = [], items = [] }) => {
    if (!items.length) {
        return;
    }
    const swiperRef = useRef();

    const [mounted, setMounted] = useState(false);
    const [shouldRenderSwiper, setShouldRenderSwiper] = useState(false);
    const [isActiveIndex, setIsActiveIndex] = useState(null);

    const handleResize = debounce(() => {
        setShouldRenderSwiper(window.innerWidth < 768);
    }, 300);

    useEffect(() => {
        setShouldRenderSwiper(window.innerWidth < 768);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setMounted(true);
    }, []);

    const swiperItems = items.length <= 3 ? [...items, ...items] : items;

    SwiperCore.use([Autoplay]);
    const sliderSettings = {
        modules: { A11y, Autoplay, Navigation, Scrollbar },
        spaceBetween: 0,
        slidesPerView: 1,
        slidesPerGroup: 1,
        centeredSlides: true,
        loop: true,
        autoplay: {
            delay: 3000,
        },
        scrollbar: { draggable: true },
        mousewheel: {
            forceToAxis: true,
            releaseOnEdges: true,
        },
    };

    const handleClick = (index) => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideToLoop(index);
            setIsActiveIndex(index);
        }
    };

    return (
        mounted && (
            <div className={styles['NewsList']}>
                <div className={styles['NewsList__Header']}>
                    <h2 className={styles['NewsList__Title']}>{title}</h2>
                    <ul className={styles['NewsList__List']}>
                        <li className={styles['NewsList__Item']}>
                            <a
                                className={styles['NewsList__Link']}
                                href={link.href}>
                                {link.title}
                            </a>
                        </li>
                        {links.map((item, index) => (
                            <li
                                key={index + item.link.title}
                                className={styles['NewsList__Item']}>
                                <a
                                    className={styles['NewsList__Link']}
                                    href={item.link.href}>
                                    {item.link.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                {shouldRenderSwiper ? (
                    <>
                        <Swiper
                            ref={swiperRef}
                            className={styles['NewsList__Grid']}
                            onSlideChange={(swiper) => {
                                setIsActiveIndex(swiper.realIndex);
                            }}
                            {...sliderSettings}>
                            {swiperItems.map((item, index) => (
                                <SwiperSlide
                                    key={index}
                                    className={styles['NewsList__Card']}>
                                    <CardNews
                                        modifiers={['noBorder']}
                                        {...item}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className={styles['NewsList__Pagination']}>
                            {items.map((_, index) => (
                                <Bullet
                                    key={index}
                                    index={index}
                                    isActiveIndex={isActiveIndex}
                                    itemsLength={items.length}
                                    handleClick={handleClick}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <ul className={styles['NewsList__Grid']}>
                        {items.map((item, index) => (
                            <li
                                key={index}
                                className={styles['NewsList__Card']}>
                                <CardNews
                                    key={index}
                                    modifiers={['noBorder']}
                                    {...item}
                                />
                            </li>
                        ))}
                    </ul>
                )}
                <div className={styles['NewsList__Button']}>
                    <Button link={link} modifiers={['tertiary']} />
                </div>
            </div>
        )
    );
};

const Bullet = ({
    isActiveIndex = null,
    index = null,
    itemsLength = 0,
    handleClick,
}) => {
    const isActive = isActiveIndex % itemsLength === index % itemsLength;

    const classes = classNames(styles['NewsList__Bullet'], {
        [styles['NewsList__Bullet--Active']]: isActive,
    });

    return (
        <button className={classes} onClick={() => handleClick(index)}>
            <span className={'sr-only'}>Navigate to slide {index + 1}</span>
        </button>
    );
};

NewsList.propTypes = {};

export default NewsList;
